import { FC, memo, useRef } from 'react';

import styles from './ProjectsPage.module.scss';

export const ProjectsPage: FC = memo(() => {
  const projectsPageRef = useRef(null);
  return (
    <div className={styles.ProjectsPage} ref={projectsPageRef}>
      <span style={{ fontWeight: 'bold' }}>projects</span>
    </div>
  );
});
