import { useAppSelector } from 'redux/hooks';

import { getAreHomePageWidgetsActive } from 'redux/slices/main/exports';
import { CurrentlyViewedPageType } from 'redux/types';
import { RoutesEnum } from 'router/routes';

export const useHomePageWidgetLink = (linkSrc: CurrentlyViewedPageType) => {
  const areHomePageWidgetsActive = useAppSelector(getAreHomePageWidgetsActive);
  const actualLinkSrc = areHomePageWidgetsActive ? `/${linkSrc}` : RoutesEnum.ROOT_PATH_URL;
  const cursor = areHomePageWidgetsActive ? 'pointer' : 'default';
  return {
    actualLinkSrc,
    cursor,
    areHomePageWidgetsActive,
  };
};
