import { FC, memo, useRef, Children, cloneElement, ReactElement } from 'react';
import { Transition } from 'react-transition-group';

import { ReactChildrenType } from 'types/types';
import { HomeButton } from './components/HomeButton/HomeButton';
import { NavLinkGroupType } from './types';
import { useLinksGroup } from 'hooks/useLinksGroup';
import { PAGE_CHANGE_DURATION } from 'constants/gsapConstants';
import { classNames } from 'helpers/classNames';
import { defaultStyle, transitionStyles } from './transitionConstants';
import styles from './NavLinksGroup.module.scss';

interface Props {
  mainChild: ReactElement;
  userSideMenuToggler?: ReactElement;
  type: NavLinkGroupType;
  isAuthorizationLinksGroup?: boolean;
  children?: ReactChildrenType;
}

export const NavLinksGroup: FC<Props> = memo(
  ({ mainChild, userSideMenuToggler, children, type, isAuthorizationLinksGroup }) => {
    const linksWrapperRef = useRef(null);
    const { isNavLinksGroupCurrent } = useLinksGroup(type);
    const nanLinkTransitionDuration = PAGE_CHANGE_DURATION * 1000;
    return (
      <Transition
        in={isNavLinksGroupCurrent}
        timeout={nanLinkTransitionDuration}
        nodeRef={linksWrapperRef}
      >
        {(state) => (
          <div className={classNames(styles.NavLinksGroup, [styles['nav-links-group']])}>
            <div className={styles.homeButtonWrapper}>
              <HomeButton
                isNavLinksGroupCurrent={isNavLinksGroupCurrent}
                isAuthorizationLinksGroup={isAuthorizationLinksGroup}
              />
              {userSideMenuToggler && userSideMenuToggler}
            </div>
            <div
              className={styles.linksWrapper}
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}
              ref={linksWrapperRef}
            >
              {cloneElement(mainChild, {
                ...mainChild.props,
                isAuthorizationLinksGroup,
              })}
              <div className={styles.secondaryLinksWrapper}>
                {Children.map(children, (child) =>
                  cloneElement(child, {
                    ...child.props,
                    isAuthorizationLinksGroup,
                  }),
                )}
              </div>
            </div>
          </div>
        )}
      </Transition>
    );
  },
);
