import { FC, memo, useRef, useEffect } from 'react';
import { useAppSelector } from 'redux/hooks';

import { RegistrationSection } from './components/RegistrationSection/RegistrationSection';
import { useRegistrationData } from './hooks/useRegistrationData';
import { getCurrentlyViewedPage } from 'redux/slices/main/exports';
import styles from './RegistrationPage.module.scss';

export const RegistrationPage: FC = memo(() => {
  const registrationPageRef = useRef(null);
  const currentlyViewedPage = useAppSelector(getCurrentlyViewedPage);
  const isSignUpPageActive = 'signup' === currentlyViewedPage;
  const { clearAllRegistrationData, isRegistrationDataEmpty } = useRegistrationData();
  useEffect(() => {
    !isSignUpPageActive && !isRegistrationDataEmpty && clearAllRegistrationData();
  }, [clearAllRegistrationData, isRegistrationDataEmpty, isSignUpPageActive]);
  return (
    <div className={styles.RegistrationPage} ref={registrationPageRef}>
      <RegistrationSection />
    </div>
  );
});
