import { FC, useEffect, useRef, useState } from 'react';
import { Transition } from 'react-transition-group';
import { useAppDispatch } from 'redux/hooks';

import { HomePageWidget } from './HomePageWidget/HomePageWidget';
import { getHomePageWidgetsContainerStyles } from './transitionConstants';
import { setAreHomePageWidgetsActive } from 'redux/slices/main/exports';
import styles from './HomePageWidgets.module.scss';

export const HomePageWidgets: FC = () => {
  const homePageWidgetsRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const [isTransitionStarted, setIsTransitionStarted] = useState(false);

  const {
    homePageWidgetsContainerDefaultStyle,
    homePageWidgetsContainerTransitionDuration,
    homePageWidgetsContainerTransitionStyles,
  } = getHomePageWidgetsContainerStyles();

  const activateHomePageWidgets = () => {
    dispatch(setAreHomePageWidgetsActive(true));
  };

  useEffect(() => {
    setIsTransitionStarted(true);
  }, [isTransitionStarted]);
  return (
    <Transition
      in={isTransitionStarted}
      onEntered={activateHomePageWidgets}
      timeout={homePageWidgetsContainerTransitionDuration}
      nodeRef={homePageWidgetsRef}
    >
      {(state) => (
        <div
          className={styles.HomePageWidgets}
          style={{
            ...homePageWidgetsContainerDefaultStyle,
            ...homePageWidgetsContainerTransitionStyles[state],
          }}
          ref={homePageWidgetsRef}
        >
          <HomePageWidget linkSrc="support" />
          <HomePageWidget linkSrc="assistant" />
          <HomePageWidget linkSrc="feedback" />
        </div>
      )}
    </Transition>
  );
};
