import { TransitionStatus } from 'react-transition-group';
import { Properties } from 'csstype';

import {
  MAIN_LOGO_TEXT_APPEARING_DELAY,
  MAIN_LOGO_TEXT_APPEARING_DURATION,
} from 'components/MainLogoLive/constants';

const duration = MAIN_LOGO_TEXT_APPEARING_DURATION;
// text
export const getPartnersTextStyles = () => {
  const partnersTextDefaultStyle: Properties = {
    transition: `opacity ${MAIN_LOGO_TEXT_APPEARING_DURATION}s ease-in-out ${MAIN_LOGO_TEXT_APPEARING_DELAY}s`,
    opacity: 0,
  };
  const partnersTextTransitionStyles: Partial<Record<TransitionStatus, Properties>> = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
  };
  return {
    partnersTextDefaultStyle,
    partnersTextTransitionStyles,
    partnersTextTransitionDuration: duration * 1000,
  };
};

export const getPartnersWingStyles = () => {
  const partnersWingDefaultStyle: Properties = {
    transition: `width ${MAIN_LOGO_TEXT_APPEARING_DURATION}s ease-in-out ${MAIN_LOGO_TEXT_APPEARING_DELAY}s`,
    width: 0,
  };
  const partnersWingTransitionStyles: Partial<Record<TransitionStatus, Properties>> = {
    entering: { width: '100%' },
    entered: { width: '100%' },
  };
  return {
    partnersWingDefaultStyle,
    partnersWingTransitionStyles,
    partnersWingTransitionDuration: duration * 1000,
  };
};
