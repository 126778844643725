import { FC } from 'react';

import { PartnersHeader } from './PartnersHeader/PartnersHeader';
import { PartnersLogos } from './PartnersLogos/PartnersLogos';
import styles from './PartnersContainer.module.scss';

export const PartnersContainer: FC = () => {
  return (
    <div className={styles.PartnersContainer}>
      <PartnersHeader />
      <PartnersLogos />
    </div>
  );
};
