import { useAppDispatch } from '../redux/hooks';

import { socket } from 'config/websockets/socket';

import {
  setUserSearchKey,
  setFoundUsers,
  setIsUsersSearchResultsShown,
  setIsUserSideMenuOpened,
  setModalWindowStatus,
} from 'redux/slices/main/exports';
import {
  setIsAuth,
  setToken,
  setUserId,
  setUserEmail,
  setUserNickname,
  setUserRole,
  setIsDoctor,
  setIsPatient,
  setIsPetOwner,
  setIsVeterinarian,
  setIsSportsmen,
  setIsTrainer,
  setUserBirthDateTimestamp,
  setUserCity,
  setUserCountry,
  setUserFirstName,
  setUserLastName,
  setUserAvatar,
  setIsEmailConfirmed,
  setUserRestrictionsLevel,
  setUserRestrictionsTimestamp,
  setMyAssessments,
  setAssessmentsToApprove,
  setFeedbacks,
  setAuthMessage,
} from 'redux/slices/user/exports';
import { useLanguage } from './useLanguage/useLanguage';

export const useLogout = () => {
  const dispatch = useAppDispatch();
  const language = useLanguage();
  return () => {
    dispatch(setIsAuth(false));
    dispatch(setToken(null));
    dispatch(setUserId(null));
    dispatch(setUserEmail(null));
    dispatch(setUserNickname(null));
    dispatch(setUserRole(null));
    dispatch(setIsDoctor(false));
    dispatch(setIsPatient(false));
    dispatch(setIsPetOwner(false));
    dispatch(setIsVeterinarian(false));
    dispatch(setIsSportsmen(false));
    dispatch(setIsTrainer(false));
    dispatch(setUserBirthDateTimestamp(null));
    dispatch(setUserCity(null));
    dispatch(setUserCountry(null));
    dispatch(setUserFirstName(null));
    dispatch(setUserLastName(null));
    dispatch(setUserAvatar(undefined));
    dispatch(setIsEmailConfirmed(false));
    dispatch(setUserRestrictionsLevel(undefined));
    dispatch(setUserRestrictionsTimestamp(undefined));
    dispatch(setUserSearchKey(''));
    dispatch(setFoundUsers([]));
    dispatch(setIsUsersSearchResultsShown(false));
    dispatch(setMyAssessments([]));
    dispatch(setAssessmentsToApprove([]));
    dispatch(setFeedbacks([]));
    dispatch(setIsUserSideMenuOpened(false));
    dispatch(
      setAuthMessage({ message: language('youSuccessfullyLoggedOut'), severity: 'positive' }),
    );
    dispatch(setModalWindowStatus('closed'));
    socket.emit('userLoggedOut');
  };
};
