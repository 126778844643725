import { FC, memo, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import { getCurrentLanguage } from 'redux/slices/main/exports';
import {
  useAnimateExtension,
  useAnimateHighlighting,
  useAnimateShadowing,
} from './hooks/useAnimate';
import {
  getAppliedInfoText,
  getIsPreviousQuestionAnswered,
  getQuestionSwitcherTitle,
  getRegistrationDataError,
} from './utils';
import { classNames } from 'helpers/classNames';
import {
  getCurrentRegistrationQuestionNumber,
  getRegistrationDataApplied,
  getRegistrationDataAppliedErrors,
  setCurrentRegistrationQuestionNumber,
} from 'redux/slices/user/exports';
import { RegistrationQuestionNumberType } from 'pages/RegistrationPage/components/RegistrationSection/types';
import styles from './RegistrationQuestionSwitcher.module.scss';

interface Props {
  questionNumber: RegistrationQuestionNumberType;
}

export const RegistrationQuestionSwitcher: FC<Props> = memo(({ questionNumber }) => {
  const indicatorRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLParagraphElement>(null);
  const dispatch = useAppDispatch();
  const currentLanguage = useAppSelector(getCurrentLanguage);
  const registrationAppliedDataErrors = useAppSelector(getRegistrationDataAppliedErrors);
  const registrationDataApplied = useAppSelector(getRegistrationDataApplied);
  const currentRegistrationQuestionNumber = useAppSelector(getCurrentRegistrationQuestionNumber);
  const isCurrentQuestion = questionNumber === currentRegistrationQuestionNumber;
  const title = getQuestionSwitcherTitle(questionNumber, currentLanguage);
  const appliedInfoText = getAppliedInfoText(
    questionNumber,
    registrationDataApplied,
    isCurrentQuestion,
  );
  const isPreviousQuestionAnswered = getIsPreviousQuestionAnswered(
    questionNumber,
    registrationDataApplied,
  );
  const isClickable = !isCurrentQuestion && (!!appliedInfoText || isPreviousQuestionAnswered);
  const isCurrentQuestionOrAlreadyAnswered =
    questionNumber <= currentRegistrationQuestionNumber || !!appliedInfoText;
  const hasRegistrationDataError = getRegistrationDataError(
    questionNumber,
    registrationAppliedDataErrors,
  );
  useAnimateHighlighting(isCurrentQuestion, titleRef);
  useAnimateExtension(isCurrentQuestionOrAlreadyAnswered, hasRegistrationDataError, indicatorRef);
  useAnimateShadowing(isCurrentQuestion, hasRegistrationDataError, indicatorRef);

  const changeCurrentRegistrationQuestionNumber = () => {
    appliedInfoText && dispatch(setCurrentRegistrationQuestionNumber(questionNumber));
  };
  return (
    <div
      className={classNames(styles.RegistrationQuestionSwitcher, [], {
        [styles.clickable]: isClickable,
      })}
      onClick={changeCurrentRegistrationQuestionNumber}
    >
      <p className={styles.appliedInfoText}>{appliedInfoText}</p>
      <div className={styles.indicatorWrapper}>
        <div className={styles.indicator} ref={indicatorRef}></div>
      </div>
      <p className={styles.title} ref={titleRef}>
        {title}
      </p>
    </div>
  );
});
