import { TransitionStatus } from 'react-transition-group';
import { Properties } from 'csstype';

import {
  MAIN_LOGO_TEXT_APPEARING_DELAY,
  MAIN_LOGO_TEXT_APPEARING_DURATION,
} from 'components/MainLogoLive/constants';

const duration = MAIN_LOGO_TEXT_APPEARING_DURATION;
// widgets
export const getHomePageWidgetsContainerStyles = () => {
  const homePageWidgetsContainerDefaultStyle: Properties = {
    transition: `opacity ${MAIN_LOGO_TEXT_APPEARING_DURATION}s ease-in-out ${MAIN_LOGO_TEXT_APPEARING_DELAY}s`,
    opacity: 0,
  };
  const homePageWidgetsContainerTransitionStyles: Partial<Record<TransitionStatus, Properties>> = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
  };
  return {
    homePageWidgetsContainerDefaultStyle,
    homePageWidgetsContainerTransitionStyles,
    homePageWidgetsContainerTransitionDuration: duration * 1000,
  };
};
