import { CLOSED_MODAL_WINDOW_DATA_DEFAULTS } from 'redux/constants';
import { MainState } from './types';

export const mainSliceInitialState: MainState = {
  currentLanguage: 'en',
  theme: 'dark',
  isFirstLoad: true,
  isAuthModalActive: false,
  isContentContainerHovered: false,
  isUsersSearchResultsShown: false,
  userSearchKey: '',
  foundUsers: [],
  searchUsersStatus: 'idle',
  appLoadingStatus: 'idle',
  currentlyViewedPage: 'home',
  isLanguageSwitcherRolled: false,
  isPasswordRecoveryMode: false,
  passwordRecoveringEmail: null,
  isPasswordRecoveryProcessStarted: false,
  onlineUsersNicknames: [],
  openedAssessmentRegistration: null,
  isUserSideMenuOpened: false,
  modalWindowStatus: 'closed',
  closedModalWindowData: CLOSED_MODAL_WINDOW_DATA_DEFAULTS,
  areHomePageWidgetsActive: false,
};
