import { createRef } from 'react';

import { RoutesEnum } from 'router/routes';
import { ReactTransitionGroupRouteObject } from './types';
import { AssistantPage } from 'pages/AssistantPage/AssistantPage';
import { HomePage } from 'pages/HomePage/HomePage';
import { InfoPage } from 'pages/InfoPage/InfoPage';
import { LoginPage } from 'pages/LoginPage/LoginPage';
import { ProjectsPage } from 'pages/ProjectsPage/ProjectsPage';
import { RegistrationPage } from 'pages/RegistrationPage/RegistrationPage';
import { SupportPage } from 'pages/SupportPage/SupportPage';
import { FeedbackPage } from 'pages/FeedbackPage/FeedbackPage';

export const routes: Array<ReactTransitionGroupRouteObject> = [
  {
    path: RoutesEnum.ROOT_PATH_URL,
    name: 'Home page',
    element: <HomePage />,
    nodeRef: createRef<HTMLDivElement>(),
  },
  {
    path: RoutesEnum.SPORT_ASSISTANT_URL,
    name: 'Sport Assistant page',
    element: <AssistantPage />,
    nodeRef: createRef<HTMLDivElement>(),
  },
  {
    path: RoutesEnum.SIGN_IN_URL,
    name: 'Sign in page',
    element: <LoginPage />,
    nodeRef: createRef<HTMLDivElement>(),
  },
  {
    path: RoutesEnum.SIGN_UP_URL,
    name: 'Sign up page',
    element: <RegistrationPage />,
    nodeRef: createRef<HTMLDivElement>(),
  },
  {
    path: RoutesEnum.INFO_URL,
    name: 'Information page',
    element: <InfoPage />,
    nodeRef: createRef<HTMLDivElement>(),
  },
  {
    path: RoutesEnum.PROJECTS_URL,
    name: 'Projects page',
    element: <ProjectsPage />,
    nodeRef: createRef<HTMLDivElement>(),
  },
  {
    path: RoutesEnum.SUPPORT_URL,
    name: 'Support page',
    element: <SupportPage />,
    nodeRef: createRef<HTMLDivElement>(),
  },
  {
    path: RoutesEnum.FEEDBACK_URL,
    name: 'Feedback page',
    element: <FeedbackPage />,
    nodeRef: createRef<HTMLDivElement>(),
  },
];
