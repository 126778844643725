import { CurrentLanguageType, ModalWindowStatusType, Undefinable } from 'types/types';
import { language } from 'utils/language';

export const getIsModalWindowAcceptingType = (
  modalWindowStatus: ModalWindowStatusType,
): boolean => {
  switch (modalWindowStatus) {
    case 'log out':
      return true;
    default:
      return false;
  }
};

export const getModalWindowAcceptanceText = (
  currentLanguage: CurrentLanguageType,
  modalWindowStatus: ModalWindowStatusType,
): Undefinable<string> => {
  switch (modalWindowStatus) {
    case 'log out':
      return language(currentLanguage, 'doYoWantLogoutCap');
    default:
      return;
  }
};
