import { AVAILABLE_LANGUAGES } from 'constants/constants';
import { useAppSelector } from '../../redux/hooks';

import { TRANSLATIONS } from 'constants/translations';
import { getCurrentLanguage } from 'redux/slices/main/exports';

export const useLanguage = () => {
  const currentLanguage = useAppSelector(getCurrentLanguage);
  return (wordOrPhrase: string) => {
    return (
      TRANSLATIONS?.[wordOrPhrase]?.[
        AVAILABLE_LANGUAGES.find((rightLanguage) => rightLanguage === currentLanguage)
          ? currentLanguage
          : 'en'
      ] || 'no translation :('
    );
  };
};
