import { memo, forwardRef, CSSProperties } from 'react';

import { ReactChildrenType } from 'types/types';
import styles from './UserSideMenuContainer.module.scss';

interface Props {
  style: CSSProperties;
  children?: ReactChildrenType;
}

export const UserSideMenuContainer = memo(
  forwardRef<HTMLDivElement, Props>(({ children, style }, ref) => {
    return (
      <div className={styles.UserSideMenuContainer} style={style} ref={ref}>
        {children}
      </div>
    );
  }),
);
