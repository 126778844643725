import { FC, memo } from 'react';
import { useAppSelector } from 'redux/hooks';

import { ButtonRemastered } from 'components/ButtonRemastered/ButtonRemastered';
import { useLanguage } from 'hooks/useLanguage/useLanguage';
import { useAuthorizationDataError } from 'pages/hooks/useAuthorizationDataError';
import { getAuthStatus } from 'redux/slices/user/exports';
import { AuthorizationDataValidationInfo } from 'components/AuthorizationDataValidationInfo/AuthorizationDataValidationInfo';
import { useThunks } from 'redux/thunks/hooks/useThunks';
import { PasswordRecoveryModeDisabler } from '../PasswordRecoveryModeDisabler/PasswordRecoveryModeDisabler';
import styles from './PasswordRecoveryBlock.module.scss';

export const PasswordRecoveryBlock: FC = memo(() => {
  const language = useLanguage();
  const { getPasswordRecoveryCode } = useThunks();
  const { isValidatedFieldEmpty, validationError } =
    useAuthorizationDataError('password-recovering');
  const isLoading = useAppSelector(getAuthStatus) === 'loading';
  const isLoginButtonDisabled = Boolean(validationError || isValidatedFieldEmpty) || isLoading;
  const buttonText = language('getCodeCap');
  return (
    <div className={styles.PasswordRecoveryBlock}>
      <PasswordRecoveryModeDisabler />
      <ButtonRemastered
        isFullWidth
        isDisabled={isLoginButtonDisabled}
        buttonText={buttonText}
        onClick={getPasswordRecoveryCode}
      />
      <AuthorizationDataValidationInfo validationError={validationError} />
    </div>
  );
});
