import { CurrentLanguageType, ILocalStorageSave, Nullable, Undefinable } from 'types/types';
import { CurrentlyViewedPageType, RequestRESTMethodType, RequestStatusType } from './types';
import {
  PASSWORD_RECOVERY_SESSION_AWAITING_TIME,
  RECOVERY_CODE_AWAITING_TIME,
} from '../constants/constants';
import { RoutesEnum } from 'router/routes';

export const universalFetch = (
  url: string,
  method: RequestRESTMethodType,
  body?: string | FormData,
  token?: string,
): Undefinable<Promise<Response>> => {
  try {
    if (token) {
      if (typeof body !== 'string') {
        return fetch(url, {
          method,
          body,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } else {
        return fetch(url, {
          method,
          body,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
      }
    } else {
      if (typeof body !== 'string') {
        return fetch(url, {
          method,
          body,
        });
      } else {
        return fetch(url, {
          method,
          body,
          headers: {
            'Content-Type': 'application/json',
          },
        });
      }
    }
  } catch (exception: unknown) {
    if (exception instanceof Error) {
      console.error('\x1b[40m\x1b[31m\x1b[1m', exception.message);
    }
  }
};

export const getPreloadedState = (): {
  currentLanguage: CurrentLanguageType;
  token: Nullable<string>;
  recoveryCodeTimerTimestamp: Nullable<number>;
  recoverSessionTimerTimestamp: Nullable<number>;
  recoverySessionId: Nullable<string>;
  passwordRecoveringEmail: Nullable<string>;
  authStatus: RequestStatusType;
} => {
  const save: Nullable<string> = localStorage.getItem('deguz-save');
  if (save) {
    const saveData: ILocalStorageSave = JSON.parse(save);
    const { currentLanguage, token, recoverySessionId, passwordRecoveringEmail } = saveData;
    let { recoveryCodeTimerTimestamp, recoverSessionTimerTimestamp } = saveData;
    const currentDateStamp = Date.now();
    recoveryCodeTimerTimestamp =
      currentDateStamp - recoveryCodeTimerTimestamp > RECOVERY_CODE_AWAITING_TIME
        ? null
        : recoveryCodeTimerTimestamp;
    recoverSessionTimerTimestamp =
      currentDateStamp - recoverSessionTimerTimestamp > PASSWORD_RECOVERY_SESSION_AWAITING_TIME
        ? null
        : recoverSessionTimerTimestamp;
    return {
      currentLanguage: currentLanguage || 'en',
      token: token || null,
      recoveryCodeTimerTimestamp: recoveryCodeTimerTimestamp,
      recoverSessionTimerTimestamp: recoverSessionTimerTimestamp,
      recoverySessionId: recoverySessionId || null,
      passwordRecoveringEmail: passwordRecoveringEmail || null,
      authStatus: token ? 'loading' : 'idle',
    };
  } else {
    return {
      currentLanguage:
        navigator.language.includes('ru') || navigator.language.includes('RU') ? 'ru' : 'en',
      token: null,
      recoveryCodeTimerTimestamp: null,
      recoverSessionTimerTimestamp: null,
      recoverySessionId: null,
      passwordRecoveringEmail: null,
      authStatus: 'idle',
    };
  }
};

export const getPreloadedCurrentlyViewedPage = (): Record<
  'currentlyViewedPage',
  CurrentlyViewedPageType
> => {
  const { pathname } = location;
  return (() => {
    switch (true) {
      case pathname === RoutesEnum.SPORT_ASSISTANT_URL:
        return { currentlyViewedPage: 'assistant' };
      case pathname === RoutesEnum.INFO_URL:
        return { currentlyViewedPage: 'info' };
      case pathname === RoutesEnum.PROJECTS_URL:
        return { currentlyViewedPage: 'projects' };
      case pathname === RoutesEnum.SUPPORT_URL:
        return { currentlyViewedPage: 'support' };
      case pathname === RoutesEnum.FEEDBACK_URL:
        return { currentlyViewedPage: 'feedback' };
      case pathname === RoutesEnum.SIGN_IN_URL:
        return { currentlyViewedPage: 'signin' };
      case pathname === RoutesEnum.SIGN_UP_URL:
        return { currentlyViewedPage: 'signup' };
      case pathname === RoutesEnum.ROOT_PATH_URL:
        return { currentlyViewedPage: 'home' };
      default:
        return { currentlyViewedPage: 'home' };
    }
  })();
};
