import { FC, memo, useRef } from 'react';

import { MainLogoLive } from 'components/MainLogoLive/MainLogoLive';
import { PartnersContainer } from './components/PartnersContainer/PartnersContainer';
import { HomePageWidgets } from './components/HomePageWidgets/HomePageWidgets';
import styles from './HomePage.module.scss';

export const HomePage: FC = memo(() => {
  const homePageRef = useRef(null);
  return (
    <div className={styles.HomePage} ref={homePageRef}>
      <HomePageWidgets />
      <MainLogoLive />
      <PartnersContainer />
    </div>
  );
});
