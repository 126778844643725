import { FC, memo } from 'react';

import { useLanguage } from 'hooks/useLanguage/useLanguage';
import { usePasswordRecovery } from 'hooks/usePasswordRecovery';
import styles from './PasswordRecoveryModeSwitcher.module.scss';

export const PasswordRecoveryModeSwitcher: FC = memo(() => {
  const language = useLanguage();
  const { enablePasswordRecoveryMode } = usePasswordRecovery();
  return (
    <div className={styles.PasswordRecoveryModeSwitcher}>
      <span onClick={enablePasswordRecoveryMode}>{language('forgotMyPassword')}</span>
    </div>
  );
});
