import {
  PASSWORD_RECOVERY_SESSION_AWAITING_TIME,
  RECOVERY_CODE_AWAITING_TIME,
} from 'constants/constants';
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  getIsPasswordRecoveryMode,
  getIsPasswordRecoveryProcessStarted,
  setIsPasswordRecoveryMode,
  setIsPasswordRecoveryProcessStarted,
} from 'redux/slices/main/exports';
import {
  setRecoverySessionId,
  setRecoveryCodeTimerTimestamp,
  setRecoverSessionTimerTimestamp,
  getRecoverySessionId,
  getRecoveryCodeTimerTimestamp,
  getRecoverSessionTimerTimestamp,
  getIsAuth,
} from 'redux/slices/user/exports';

export const usePasswordRecovery = () => {
  const dispatch = useAppDispatch();
  const isAuth = useAppSelector(getIsAuth);
  const recoverySessionId = useAppSelector(getRecoverySessionId);
  const recoveryCodeTimerTimestamp = useAppSelector(getRecoveryCodeTimerTimestamp);
  const recoverSessionTimerTimestamp = useAppSelector(getRecoverSessionTimerTimestamp);
  const isPasswordRecoveryMode = useAppSelector(getIsPasswordRecoveryMode);
  const isPasswordRecoveryProcessStarted = useAppSelector(getIsPasswordRecoveryProcessStarted);

  const isConfirmationRecoveryCodeBlockShown =
    isPasswordRecoveryMode && Boolean(recoveryCodeTimerTimestamp);
  const isChangingPasswordBlockShown = isPasswordRecoveryMode && recoverSessionTimerTimestamp;
  const isGettingRecoveryCodeBlockShown =
    isPasswordRecoveryMode &&
    (!isConfirmationRecoveryCodeBlockShown || !isChangingPasswordBlockShown);
  const isLoginBlockShown = !isConfirmationRecoveryCodeBlockShown && !isChangingPasswordBlockShown;

  const isPasswordRecoveringDataMustBeCleared = useCallback(() => {
    const currentTimeStamp = Date.now();
    const isRecoveryCodeTimerTimestampOutdated = !recoveryCodeTimerTimestamp
      ? false
      : currentTimeStamp - recoveryCodeTimerTimestamp > RECOVERY_CODE_AWAITING_TIME;
    const isRecoverSessionTimerTimestampOutdated = !recoverSessionTimerTimestamp
      ? false
      : currentTimeStamp - recoverSessionTimerTimestamp > PASSWORD_RECOVERY_SESSION_AWAITING_TIME;
    const mustBeCleared =
      isRecoveryCodeTimerTimestampOutdated || isRecoverSessionTimerTimestampOutdated || isAuth;
    return mustBeCleared;
  }, [isAuth, recoverSessionTimerTimestamp, recoveryCodeTimerTimestamp]);

  const clearPasswordRecoveringData = useCallback(() => {
    if (!isPasswordRecoveringDataMustBeCleared()) return;
    recoverySessionId && dispatch(setRecoverySessionId(null));
    recoveryCodeTimerTimestamp && dispatch(setRecoveryCodeTimerTimestamp(null));
    recoverSessionTimerTimestamp && dispatch(setRecoverSessionTimerTimestamp(null));
    isPasswordRecoveryMode && dispatch(setIsPasswordRecoveryMode(false));
    isPasswordRecoveryProcessStarted && dispatch(setIsPasswordRecoveryProcessStarted(false));
  }, [
    dispatch,
    isPasswordRecoveringDataMustBeCleared,
    isPasswordRecoveryMode,
    isPasswordRecoveryProcessStarted,
    recoverSessionTimerTimestamp,
    recoveryCodeTimerTimestamp,
    recoverySessionId,
  ]);

  const enablePasswordRecoveryMode = useCallback(() => {
    isPasswordRecoveringDataMustBeCleared() && clearPasswordRecoveringData();
    !isPasswordRecoveryMode && dispatch(setIsPasswordRecoveryMode(true));
  }, [
    clearPasswordRecoveringData,
    dispatch,
    isPasswordRecoveringDataMustBeCleared,
    isPasswordRecoveryMode,
  ]);

  const disablePasswordRecoveryMode = useCallback(() => {
    isPasswordRecoveringDataMustBeCleared() && clearPasswordRecoveringData();
    isPasswordRecoveryMode && dispatch(setIsPasswordRecoveryMode(false));
  }, [
    clearPasswordRecoveringData,
    dispatch,
    isPasswordRecoveringDataMustBeCleared,
    isPasswordRecoveryMode,
  ]);

  return {
    clearPasswordRecoveringData,
    enablePasswordRecoveryMode,
    disablePasswordRecoveryMode,
    isGettingRecoveryCodeBlockShown,
    isConfirmationRecoveryCodeBlockShown,
    isChangingPasswordBlockShown,
    isLoginBlockShown,
  };
};
