import { FC, memo } from 'react';

import { getTimerMillisecondsLeftAndStrokeDashOffset } from './utils';
import { FULL_CIRCLE_TIMER_DASH_OFFSET } from './constants';
import styles from './CodeConfirmationTimer.module.scss';
import './CodeConfirmationTimer.scss';

interface Props {
  timerDurationMilliseconds: number;
  isTimerStarted: boolean;
  onEndCallback: VoidFunction;
  limiterTimestamp?: number;
}

export const CodeConfirmationTimer: FC<Props> = memo(
  ({ isTimerStarted, timerDurationMilliseconds, limiterTimestamp, onEndCallback }) => {
    if (!isTimerStarted) return null;

    const { lefDurationMilliseconds, startDashOffsetValue } =
      getTimerMillisecondsLeftAndStrokeDashOffset(timerDurationMilliseconds, limiterTimestamp);
    return (
      <svg
        className={styles.CodeConfirmationTimer}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
      >
        <circle
          cx="50"
          cy="50"
          r="45"
          stroke="#9031cf"
          strokeWidth="10"
          fill="none"
          strokeLinecap="round"
          strokeDasharray={FULL_CIRCLE_TIMER_DASH_OFFSET}
          strokeDashoffset={startDashOffsetValue}
          transform="rotate(50 50)"
          style={{
            animationName: 'timer-going',
            animationDuration: `${lefDurationMilliseconds}ms`,
            animationTimingFunction: 'linear',
          }}
          onAnimationEnd={onEndCallback}
        ></circle>
      </svg>
    );
  },
);
