export enum RoutesEnum {
  ROOT_PATH_URL = '/',
  SPORT_ASSISTANT_URL = '/assistant',
  SIGN_IN_URL = '/signin',
  SIGN_UP_URL = '/signup',
  INFO_URL = '/info',
  SUPPORT_URL = '/support',
  PROJECTS_URL = '/projects',
  FEEDBACK_URL = '/feedback',
}
