import { TransitionStatus } from 'react-transition-group';
import { Properties } from 'csstype';

import {
  MAIN_LOGO_TEXT_APPEARING_DELAY,
  MAIN_LOGO_TEXT_APPEARING_DURATION,
} from 'components/MainLogoLive/constants';
import { PAGE_CHANGE_DURATION } from 'constants/gsapConstants';

const duration = MAIN_LOGO_TEXT_APPEARING_DURATION;
// widget border and transform
export const getHomePageWidgetBorderStyles = () => {
  const borderColorStart = 'rgba(34, 34, 34, 0)';
  const borderColorEnd = 'rgba(34, 34, 34, 1)';
  const homePageWidgetBorderDefaultStyle: Properties = {
    transition: `border-color ${MAIN_LOGO_TEXT_APPEARING_DURATION}s ease-in-out ${
      MAIN_LOGO_TEXT_APPEARING_DELAY * 1.5
    }s, transform ${PAGE_CHANGE_DURATION}s ease-in-out`,
    borderColor: borderColorStart,
  };
  const homePageWidgetBorderTransitionStyles: Partial<Record<TransitionStatus, Properties>> = {
    entering: { borderColor: borderColorEnd },
    entered: { borderColor: borderColorEnd },
  };
  return {
    homePageWidgetBorderDefaultStyle,
    homePageWidgetBorderTransitionStyles,
    homePageWidgetBorderTransitionDuration: duration * 1000,
  };
};
