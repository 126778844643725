import { FC, memo, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import { getUserNickname } from 'redux/slices/user/exports';
import { useAnimateAppearing } from './hooks/useAnimate';
import { setIsUserSideMenuOpened } from 'redux/slices/main/exports';
import styles from './UserSideMenuOpener.module.scss';

export const UserSideMenuOpener: FC = memo(() => {
  const userSideMenuOpenerRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const userNickname = useAppSelector(getUserNickname);
  useAnimateAppearing(userSideMenuOpenerRef);
  const openUserSideMenu = () => {
    dispatch(setIsUserSideMenuOpened(true));
  };
  return (
    <div
      className={styles.UserSideMenuOpener}
      ref={userSideMenuOpenerRef}
      onClick={openUserSideMenu}
    >
      <span>{userNickname}</span>
    </div>
  );
});
