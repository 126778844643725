import { FC } from 'react';

import { useLanguage } from 'hooks/useLanguage/useLanguage';
import { usePasswordRecovery } from 'hooks/usePasswordRecovery';
import styles from './PasswordRecoveryModeDisabler.module.scss';

export const PasswordRecoveryModeDisabler: FC = () => {
  const language = useLanguage();
  const { disablePasswordRecoveryMode } = usePasswordRecovery();
  const returnText = language('returnToLoginCap');
  return (
    <div className={styles.PasswordRecoveryModeDisabler}>
      <span onClick={disablePasswordRecoveryMode}>{returnText}</span>
    </div>
  );
};
