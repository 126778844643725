import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';

import {
  AssessmentType,
  CurrentLanguageType,
  IFoundUserData,
  ModalWindowStatusType,
  Nullable,
  ThemeType,
} from 'types/types';
import { searchUsersAsync } from '../../thunks/mainThunks';
import { mainSliceInitialState as initialState } from './initialState';
import { MainState } from './types';
import { CurrentlyViewedPageType } from 'redux/types';
import { IClosedModalWindowData } from 'components/ModalWindow/types';

export const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setCurrentLanguage(
      state: WritableDraft<MainState>,
      { payload }: PayloadAction<CurrentLanguageType>,
    ) {
      state.currentLanguage = payload;
    },
    setTheme(state: WritableDraft<MainState>, { payload }: PayloadAction<ThemeType>) {
      state.theme = payload;
    },
    setCurrentlyViewedPage(
      state: WritableDraft<MainState>,
      { payload }: PayloadAction<CurrentlyViewedPageType>,
    ) {
      state.currentlyViewedPage = payload;
    },
    setIsLanguageSwitcherRolled(
      state: WritableDraft<MainState>,
      { payload }: PayloadAction<boolean>,
    ) {
      state.isLanguageSwitcherRolled = payload;
    },
    setIsFirstLoad(state: WritableDraft<MainState>, { payload }: PayloadAction<boolean>) {
      state.isFirstLoad = payload;
    },
    setIsAuthModalActive(state: WritableDraft<MainState>, { payload }: PayloadAction<boolean>) {
      state.isAuthModalActive = payload;
    },
    setIsContentContainerHovered(
      state: WritableDraft<MainState>,
      { payload }: PayloadAction<boolean>,
    ) {
      state.isContentContainerHovered = payload;
    },
    setIsUsersSearchResultsShown(
      state: WritableDraft<MainState>,
      { payload }: PayloadAction<boolean>,
    ) {
      state.isUsersSearchResultsShown = payload;
    },
    setUserSearchKey(state: WritableDraft<MainState>, { payload }: PayloadAction<string>) {
      state.userSearchKey = payload;
    },
    setFoundUsers(
      state: WritableDraft<MainState>,
      { payload }: PayloadAction<Array<IFoundUserData>>,
    ) {
      state.foundUsers = payload;
    },
    setIsPasswordRecoveryMode(
      state: WritableDraft<MainState>,
      { payload }: PayloadAction<boolean>,
    ) {
      state.isPasswordRecoveryMode = payload;
    },
    setPasswordRecoveringEmail(
      state: WritableDraft<MainState>,
      { payload }: PayloadAction<Nullable<string>>,
    ) {
      state.passwordRecoveringEmail = payload;
    },
    setIsPasswordRecoveryProcessStarted(
      state: WritableDraft<MainState>,
      { payload }: PayloadAction<boolean>,
    ) {
      state.isPasswordRecoveryProcessStarted = payload;
    },
    setOnlineUsersNicknames(
      state: WritableDraft<MainState>,
      { payload }: PayloadAction<Array<string>>,
    ) {
      state.onlineUsersNicknames = payload;
    },
    setOpenedAssessmentRegistration(
      state: WritableDraft<MainState>,
      { payload }: PayloadAction<Nullable<AssessmentType>>,
    ) {
      state.openedAssessmentRegistration = payload;
    },
    setIsUserSideMenuOpened(state: WritableDraft<MainState>, { payload }: PayloadAction<boolean>) {
      state.isUserSideMenuOpened = payload;
    },
    setModalWindowStatus(
      state: WritableDraft<MainState>,
      { payload }: PayloadAction<ModalWindowStatusType>,
    ) {
      state.modalWindowStatus = payload;
    },
    setClosedModalWindowData(
      state: WritableDraft<MainState>,
      { payload }: PayloadAction<IClosedModalWindowData>,
    ) {
      state.closedModalWindowData = payload;
    },
    setAreHomePageWidgetsActive(
      state: WritableDraft<MainState>,
      { payload }: PayloadAction<boolean>,
    ) {
      state.areHomePageWidgetsActive = payload;
    },
  },
  extraReducers(builder) {
    builder
      // search users
      .addCase(searchUsersAsync.pending, (state) => {
        state.searchUsersStatus = 'loading';
      })
      .addCase(searchUsersAsync.fulfilled, (state, { payload }) => {
        state.searchUsersStatus = 'idle';
        if (payload) {
          state.foundUsers = payload.searchResult;
        }
      })
      .addCase(searchUsersAsync.rejected, (state, { error }) => {
        state.searchUsersStatus = 'failed';
        console.error('\x1b[40m\x1b[31m\x1b[1m', error.message);
      });
  },
});

export const { reducer } = mainSlice;
