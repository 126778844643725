import { CurrentlyViewedPageType } from 'redux/types';

export const getWidgetMainText = (
  type: CurrentlyViewedPageType,
  languageTranslationMethod: (wordOrPhrase: string) => string,
): string => {
  switch (type) {
    case 'assistant':
      return languageTranslationMethod('mySportCap');
    case 'support':
      return languageTranslationMethod('supportNounCap');
    case 'feedback':
      return languageTranslationMethod('feedbackCap');
  }
};
