import homeLogo from '../assets/img/home-logo.svg';
import errorRoundIcon from '../assets/img/error-round.svg';
import closedEyeIcon from '../assets/img/eye-hidden.svg';
import openedEyeIcon from '../assets/img/eye-shown.svg';
import arrowRounded from '../assets/img/arrow-top.svg';
import heart from '../assets/img/heart.svg';
import acceptIcon from '../assets/img/accept-icon.svg';
import crossIcon from '../assets/img/cross-icon.svg';

export const HOME_LOGO_SRC = homeLogo;
export const ERROR_ROUND_ICON_SRC = errorRoundIcon;
export const CLOSED_EYE_ICON_SRC = closedEyeIcon;
export const OPENED_EYE_ICON_SRC = openedEyeIcon;
export const ARROW_ROUNDED_ICON_SRC = arrowRounded;
export const HEART_ICON_SRC = heart;
export const ACCEPT_ICON_SRC = acceptIcon;
export const CROSS_ICON_SRC = crossIcon;
