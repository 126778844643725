import { FC } from 'react';

import styles from './FeedbackPage.module.scss';

export const FeedbackPage: FC = () => {
  return (
    <div className={styles.FeedbackPage}>
      <span style={{ fontWeight: 'bold' }}>feedback</span>
    </div>
  );
};
