import { useAppSelector } from 'redux/hooks';

import { ModalWindowStatusType } from 'types/types';
import { getIsModalWindowAcceptingType, getModalWindowAcceptanceText } from '../utils';
import { useLogout } from 'hooks/useLogout';
import { getClosedModalWindowData, getCurrentLanguage } from 'redux/slices/main/exports';

export const useModalWindow = (modalWindowStatus: ModalWindowStatusType) => {
  const currentLanguage = useAppSelector(getCurrentLanguage);
  const {
    isModalWindowAcceptingType: closedIsModalWindowAcceptingType,
    modalWindowAcceptanceText: closedModalWindowAcceptanceText,
  } = useAppSelector(getClosedModalWindowData);
  const isModalWindowAcceptingType = getIsModalWindowAcceptingType(modalWindowStatus);
  const logOut = useLogout();
  const modalWindowAcceptMethod = !isModalWindowAcceptingType
    ? undefined
    : (() => {
        switch (modalWindowStatus) {
          case 'log out':
            return logOut;
        }
      })();
  const modalWindowAcceptanceText = getModalWindowAcceptanceText(
    currentLanguage,
    modalWindowStatus,
  );
  return {
    isModalWindowAcceptingType: isModalWindowAcceptingType || closedIsModalWindowAcceptingType,
    modalWindowAcceptanceText: modalWindowAcceptanceText || closedModalWindowAcceptanceText,
    modalWindowAcceptMethod,
  };
};
