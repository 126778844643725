import { createBrowserRouter } from 'react-router-dom';

import { App } from 'components/App';
import { socket } from 'config/websockets/socket';
import { routes } from './routes/main';
import { RoutesEnum } from 'router/routes';

export const router = createBrowserRouter([
  {
    path: RoutesEnum.ROOT_PATH_URL,
    element: <App socket={socket} />,
    children: routes.map((route) => ({
      index: route.path === RoutesEnum.ROOT_PATH_URL,
      path: route.path === RoutesEnum.ROOT_PATH_URL ? undefined : route.path,
      element: route.element,
    })),
  },
]);
