import { FC, useEffect, useRef, useState } from 'react';
import { Transition } from 'react-transition-group';

import { getPartnersTextStyles, getPartnersWingStyles } from './transitionConstants';
import { useLanguage } from 'hooks/useLanguage/useLanguage';
import styles from './PartnersHeader.module.scss';

export const PartnersHeader: FC = () => {
  const partnersHeaderTextRef = useRef<HTMLSpanElement>(null);
  const partnersFirstWingRef = useRef<HTMLDivElement>(null);
  const partnersSecondWingRef = useRef<HTMLDivElement>(null);
  const language = useLanguage();
  const [isTransitionStarted, setIsTransitionStarted] = useState(false);

  const { partnersTextDefaultStyle, partnersTextTransitionDuration, partnersTextTransitionStyles } =
    getPartnersTextStyles();
  const { partnersWingDefaultStyle, partnersWingTransitionDuration, partnersWingTransitionStyles } =
    getPartnersWingStyles();

  useEffect(() => {
    setIsTransitionStarted(true);
  }, [isTransitionStarted]);
  return (
    <Transition in={isTransitionStarted} timeout={partnersTextTransitionDuration}>
      {(state) => (
        <div className={styles.PartnersHeader}>
          <Transition
            in={isTransitionStarted}
            timeout={partnersWingTransitionDuration}
            nodeRef={partnersFirstWingRef}
          >
            <div
              className={styles.partnersWing}
              style={{ ...partnersWingDefaultStyle, ...partnersWingTransitionStyles[state] }}
              ref={partnersFirstWingRef}
            ></div>
          </Transition>
          <Transition
            in={isTransitionStarted}
            timeout={partnersTextTransitionDuration}
            nodeRef={partnersHeaderTextRef}
          >
            <span
              style={{ ...partnersTextDefaultStyle, ...partnersTextTransitionStyles[state] }}
              ref={partnersHeaderTextRef}
            >
              {language('partners')}
            </span>
          </Transition>
          <Transition
            in={isTransitionStarted}
            timeout={partnersWingTransitionDuration}
            nodeRef={partnersSecondWingRef}
          >
            <div
              className={styles.partnersWing}
              style={{ ...partnersWingDefaultStyle, ...partnersWingTransitionStyles[state] }}
              ref={partnersSecondWingRef}
            ></div>
          </Transition>
        </div>
      )}
    </Transition>
  );
};
