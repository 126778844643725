import { FC, useEffect, useRef, useState } from 'react';

import { PARTNERS_DATA } from './constants';
import { getPartnersContainerStyles } from './transitionConstants';
import styles from './PartnersLogos.module.scss';
import { Transition } from 'react-transition-group';

export const PartnersLogos: FC = () => {
  const partnersLogosRef = useRef<HTMLDivElement>(null);
  const [isTransitionStarted, setIsTransitionStarted] = useState(false);

  const {
    partnersContainerDefaultStyle,
    partnersContainerTransitionDuration,
    partnersContainerTransitionStyles,
  } = getPartnersContainerStyles();

  useEffect(() => {
    setIsTransitionStarted(true);
  }, [isTransitionStarted]);
  return (
    <Transition
      in={isTransitionStarted}
      timeout={partnersContainerTransitionDuration}
      nodeRef={partnersLogosRef}
    >
      {(state) => (
        <div
          className={styles.PartnersLogos}
          style={{ ...partnersContainerDefaultStyle, ...partnersContainerTransitionStyles[state] }}
          ref={partnersLogosRef}
        >
          {PARTNERS_DATA.map((partnerData, index) => {
            const { logoSrc } = partnerData;
            return <img src={logoSrc} alt="" key={index} />;
          })}
        </div>
      )}
    </Transition>
  );
};
