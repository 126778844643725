import { FC, memo, useRef } from 'react';
import { NavLink } from 'react-router-dom';

import { HOME_LOGO_SRC } from 'constants/assets-src';
import { useAnimateHighlighting } from './hooks/useAnimate';
import { RoutesEnum } from 'router/routes';
import styles from './HomeButton.module.scss';

interface Props {
  isNavLinksGroupCurrent: boolean;
  isAuthorizationLinksGroup: boolean;
}

export const HomeButton: FC<Props> = memo(
  ({ isNavLinksGroupCurrent, isAuthorizationLinksGroup }) => {
    const homeButtonRef = useRef(null);
    useAnimateHighlighting(isNavLinksGroupCurrent, isAuthorizationLinksGroup, homeButtonRef);
    return (
      <NavLink to={RoutesEnum.ROOT_PATH_URL} className={styles.HomeButton} ref={homeButtonRef}>
        <img draggable={false} src={HOME_LOGO_SRC} alt="a house with a triangle roof" />
      </NavLink>
    );
  },
);
