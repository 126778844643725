import { FC, memo, useRef } from 'react';
import { useAppSelector } from 'redux/hooks';

import { getIsAuth } from 'redux/slices/user/exports';
import { AssistantIntroduction } from './components/AssistantIntroduction/AssistantIntroduction';
import styles from './AssistantPage.module.scss';

export const AssistantPage: FC = memo(() => {
  const assistantPageRef = useRef(null);
  const isAuth = useAppSelector(getIsAuth);
  return (
    <div className={styles.AssistantPage} ref={assistantPageRef}>
      {isAuth ? null : <AssistantIntroduction />}
    </div>
  );
});
