import { MutableRefObject } from 'react';

import { useGSAPTimeline } from 'hooks/GSAP/useGSAPTimeline';
import { PAGE_CHANGE_DURATION, ZERO_GSAP_DURATION } from 'constants/gsapConstants';

export const useAnimateAppearing = (
  isElementActive: boolean,
  scopeElement: MutableRefObject<HTMLDivElement>,
) => {
  const opacity = !isElementActive ? 0 : 1;
  const {
    timeline: appearingTimeline,
    isFirstLoad,
    setIsFirstLoad,
  } = useGSAPTimeline(false, undefined, scopeElement, [isElementActive]);
  isElementActive && isFirstLoad && setIsFirstLoad(false);
  const displayType = isElementActive ? 'flex' : 'none';
  const duration = isFirstLoad ? ZERO_GSAP_DURATION : PAGE_CHANGE_DURATION;
  const delay = !isElementActive ? ZERO_GSAP_DURATION : PAGE_CHANGE_DURATION;
  appearingTimeline &&
    appearingTimeline.to(scopeElement.current, {
      opacity,
      display: displayType,
      duration,
      delay,
      onComplete: () => {
        isFirstLoad && setIsFirstLoad(false);
      },
    });
};
