import { TransitionStatus } from 'react-transition-group';
import { Properties } from 'csstype';

import {
  MAIN_LOGO_TEXT_APPEARING_DELAY,
  MAIN_LOGO_TEXT_APPEARING_DURATION,
} from 'components/MainLogoLive/constants';

const duration = MAIN_LOGO_TEXT_APPEARING_DURATION;

export const getPartnersContainerStyles = () => {
  const partnersContainerDefaultStyle: Properties = {
    transition: `opacity ${MAIN_LOGO_TEXT_APPEARING_DURATION}s ease-in-out ${MAIN_LOGO_TEXT_APPEARING_DELAY}s`,
    opacity: 0,
  };
  const partnersContainerTransitionStyles: Partial<Record<TransitionStatus, Properties>> = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
  };
  return {
    partnersContainerDefaultStyle,
    partnersContainerTransitionStyles,
    partnersContainerTransitionDuration: duration * 1000,
  };
};
