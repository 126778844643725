import { FC, useRef } from 'react';
import { Transition } from 'react-transition-group';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import {
  getModalWindowStatus,
  setClosedModalWindowData,
  setModalWindowStatus,
} from 'redux/slices/main/exports';
import {
  getBackgroundStyles,
  getModalWindowContainerStyles,
  getModalWindowVisibilityStyles,
} from './transitionConstants';
import { useModalWindow } from './hooks/useModalWindow';
import { CLOSED_MODAL_WINDOW_DATA_DEFAULTS } from 'redux/constants';
import { ACCEPT_ICON_SRC, CROSS_ICON_SRC } from 'constants/assets-src';
import styles from './ModalWindow.module.scss';

export const ModalWindow: FC = () => {
  const modalWindowRef = useRef<HTMLDivElement>(null);
  const modalWindowBackgroundRef = useRef<HTMLDivElement>(null);
  const modalWindowContainerRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const modalWindowStatus = useAppSelector(getModalWindowStatus);
  const { isModalWindowAcceptingType, modalWindowAcceptMethod, modalWindowAcceptanceText } =
    useModalWindow(modalWindowStatus);
  const isModalWindowShown = modalWindowStatus !== 'closed';
  const {
    modalWindowVisibilityDefaultStyle,
    modalWindowVisibilityDuration,
    modalWindowVisibilityTransitionStyles,
  } = getModalWindowVisibilityStyles(isModalWindowShown);
  const { backgroundDefaultStyle, backgroundTransitionDuration, backgroundTransitionStyles } =
    getBackgroundStyles(isModalWindowShown);
  const {
    modalWindowContainerDefaultStyle,
    modalWindowContainerTransitionDuration,
    modalWindowContainerTransitionStyles,
  } = getModalWindowContainerStyles(isModalWindowShown);

  const closeModalWindow = () => {
    dispatch(setModalWindowStatus('closed'));
    dispatch(setClosedModalWindowData({ isModalWindowAcceptingType, modalWindowAcceptanceText }));
  };
  const resetClosedModalWindowData = () => {
    dispatch(setClosedModalWindowData(CLOSED_MODAL_WINDOW_DATA_DEFAULTS));
  };
  return (
    <Transition
      in={isModalWindowShown}
      timeout={modalWindowVisibilityDuration}
      nodeRef={modalWindowRef}
      mountOnEnter
      unmountOnExit
      onExited={resetClosedModalWindowData}
    >
      {(state) => (
        <div
          className={styles.ModalWindow}
          style={{
            ...modalWindowVisibilityDefaultStyle,
            ...modalWindowVisibilityTransitionStyles[state],
          }}
          ref={modalWindowRef}
        >
          <Transition
            in={isModalWindowShown}
            timeout={modalWindowContainerTransitionDuration}
            nodeRef={modalWindowContainerRef}
            mountOnEnter
            unmountOnExit
          >
            <div
              className={styles.modalWindowContainerWrapper}
              style={{
                ...modalWindowContainerDefaultStyle,
                ...modalWindowContainerTransitionStyles[state],
              }}
              ref={modalWindowContainerRef}
            >
              <div className={styles.modalWindowContainer}>
                {modalWindowAcceptanceText && <p>{modalWindowAcceptanceText}</p>}
              </div>
              <div className={styles.closeModalWindowButtonWrapper} onClick={closeModalWindow}>
                <div className={styles.closeModalWindowButton} role="button">
                  <img src={CROSS_ICON_SRC} alt="" />
                </div>
              </div>
              <div className={styles.applyModalWindowButtonContainer}>
                <div className={styles.applyModalWindowButtonWrapper}>
                  <div
                    className={styles.applyModalWindowButton}
                    role="button"
                    onClick={modalWindowAcceptMethod}
                  >
                    <img src={ACCEPT_ICON_SRC} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            in={isModalWindowShown}
            timeout={backgroundTransitionDuration}
            nodeRef={modalWindowBackgroundRef}
          >
            <div
              className={styles.modalWindowBackground}
              style={{
                ...backgroundDefaultStyle,
                ...backgroundTransitionStyles[state],
              }}
              ref={modalWindowBackgroundRef}
            ></div>
          </Transition>
        </div>
      )}
    </Transition>
  );
};
