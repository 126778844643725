import { FULL_CIRCLE_TIMER_DASH_OFFSET } from './constants';

interface ICircleTimerData {
  lefDurationMilliseconds: number;
  startDashOffsetValue: number;
}

export const getTimerMillisecondsLeftAndStrokeDashOffset = (
  timerDurationMilliseconds: number,
  limiterTimestamp?: number,
): ICircleTimerData => {
  if (!limiterTimestamp)
    return { lefDurationMilliseconds: timerDurationMilliseconds, startDashOffsetValue: 0 };
  const currentTimeStamp = Date.now();
  const lefDurationMilliseconds = limiterTimestamp + timerDurationMilliseconds - currentTimeStamp;
  const startDashOffsetValue =
    FULL_CIRCLE_TIMER_DASH_OFFSET -
    (lefDurationMilliseconds / timerDurationMilliseconds) * FULL_CIRCLE_TIMER_DASH_OFFSET;
  return {
    lefDurationMilliseconds: lefDurationMilliseconds < 0 ? 0 : lefDurationMilliseconds,
    startDashOffsetValue,
  };
};
