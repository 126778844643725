import { useAppSelector } from 'redux/hooks';

import { EMPTY_STRING } from 'constants/constants';
import { getCurrentLanguage } from 'redux/slices/main/exports';
import { CurrentLanguageType } from 'types/types';
import { language } from 'utils/language';
import { hasForbiddenCharacters } from 'utils/utils';
import {
  getCurrentRegistrationQuestionNumber,
  getLoginData,
  getRegistrationData,
  getRepeatedPassword,
} from 'redux/slices/user/exports';
import { AuthorizationDataValidationInfoType } from '../types';

interface IValidationErrorData {
  isValidatedFieldEmpty: boolean;
  validationError: string;
}

const getEmailValidationError = (
  email: string,
  currentLanguage: CurrentLanguageType,
): IValidationErrorData => {
  if (!email) return { isValidatedFieldEmpty: true, validationError: EMPTY_STRING };
  const emailValidationError = language(currentLanguage, 'specifiedEmailIncorrect');
  if (email.length < 8)
    return { validationError: emailValidationError, isValidatedFieldEmpty: false };
  const pattern =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!email.match(pattern))
    return { validationError: emailValidationError, isValidatedFieldEmpty: false };
  return { isValidatedFieldEmpty: false, validationError: EMPTY_STRING };
};

const getNicknameValidationError = (
  nickname: string,
  currentLanguage: CurrentLanguageType,
): IValidationErrorData => {
  if (!nickname) return { isValidatedFieldEmpty: true, validationError: EMPTY_STRING };
  if (hasForbiddenCharacters(nickname))
    return {
      validationError: `${language(currentLanguage, 'forbiddenCharactersCap')} ${language(
        currentLanguage,
        'inNickname',
      )}`,
      isValidatedFieldEmpty: false,
    };
  if (nickname.length < 2)
    return {
      validationError: language(currentLanguage, 'minNicknameLength2CharsCap'),
      isValidatedFieldEmpty: false,
    };
  if (nickname.length > 10)
    return {
      validationError: language(currentLanguage, 'maxNicknameLength10CharsCap'),
      isValidatedFieldEmpty: false,
    };
  return { isValidatedFieldEmpty: false, validationError: EMPTY_STRING };
};

const getPasswordValidationError = (
  password: string,
  repeatedPassword: string,
  currentLanguage: CurrentLanguageType,
): IValidationErrorData => {
  if (!password) return { isValidatedFieldEmpty: true, validationError: EMPTY_STRING };
  if (password.length < 8)
    return {
      validationError: language(currentLanguage, 'minPasswordLength8CharsCap'),
      isValidatedFieldEmpty: false,
    };
  if (password !== repeatedPassword)
    return {
      validationError: language(currentLanguage, 'passwordsDon`tMatchCap'),
      isValidatedFieldEmpty: false,
    };
  return { isValidatedFieldEmpty: false, validationError: EMPTY_STRING };
};

export const useAuthorizationDataError = (type: AuthorizationDataValidationInfoType) => {
  const currentLanguage = useAppSelector(getCurrentLanguage);
  const loginData = useAppSelector(getLoginData);
  const registrationData = useAppSelector(getRegistrationData);
  const repeatedPassword = useAppSelector(getRepeatedPassword);
  const currentRegistrationQuestionNumber = useAppSelector(getCurrentRegistrationQuestionNumber);

  const getValidationError = (): IValidationErrorData => {
    if (type === 'password-recovering') {
      const { email: recoveringEmail } = loginData;
      if (!recoveringEmail) return { isValidatedFieldEmpty: true, validationError: EMPTY_STRING };
      const emailValidationError = getEmailValidationError(recoveringEmail, currentLanguage);
      if (emailValidationError.validationError) return emailValidationError;
      return { isValidatedFieldEmpty: false, validationError: EMPTY_STRING };
    }
    if (type === 'login') {
      const { email: loginEmail, password: loginPassword } = loginData;
      if (!loginEmail && !loginPassword)
        return { isValidatedFieldEmpty: true, validationError: EMPTY_STRING };
      if (loginEmail) {
        const emailValidationError = getEmailValidationError(loginEmail, currentLanguage);
        if (emailValidationError.validationError) return emailValidationError;
      }
      if (loginPassword && !loginEmail)
        return {
          isValidatedFieldEmpty: true,
          validationError: language(currentLanguage, 'enterEmailForLoginCap'),
        };
      if (!loginPassword && loginEmail)
        return {
          isValidatedFieldEmpty: true,
          validationError: language(currentLanguage, 'enterPasswordForLoginCap'),
        };
      return { isValidatedFieldEmpty: false, validationError: EMPTY_STRING };
    } else {
      switch (currentRegistrationQuestionNumber) {
        case 1:
          return getEmailValidationError(registrationData.email, currentLanguage);
        case 2:
          return getNicknameValidationError(registrationData.nickname, currentLanguage);
        case 3:
          return getPasswordValidationError(
            registrationData.password,
            repeatedPassword,
            currentLanguage,
          );
        default:
          return { isValidatedFieldEmpty: false, validationError: EMPTY_STRING };
      }
    }
  };
  return getValidationError();
};
