import { ChangeEventHandler, FC, HTMLInputTypeAttribute, KeyboardEventHandler } from 'react';

import styles from './InputRemastered.module.scss';

interface Props {
  value: string;
  type: HTMLInputTypeAttribute;
  placeholder?: string;
  onEnter?: VoidFunction;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

export const InputRemastered: FC<Props> = ({
  type,
  value,
  onEnter,
  onChange,
  placeholder = '',
}) => {
  const onEnterPress: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key !== 'Enter') return;
    onEnter && onEnter();
  };
  return (
    <input
      className={styles.InputRemastered}
      type={type}
      value={value}
      onChange={onChange}
      onKeyDown={onEnterPress}
      placeholder={placeholder}
      spellCheck={false}
    />
  );
};
