import { FC, memo, useRef } from 'react';

import styles from './InfoPage.module.scss';

export const InfoPage: FC = memo(() => {
  const infoPageRef = useRef(null);
  return (
    <div className={styles.InfoPage} ref={infoPageRef}>
      <span style={{ fontWeight: 'bold' }}>info</span>
    </div>
  );
});
