import { PAGE_CHANGE_DURATION } from 'constants/gsapConstants';
import { TransitionStatus } from 'react-transition-group';
import { Properties } from 'csstype';

const duration = PAGE_CHANGE_DURATION;
export const defaultStyle: Properties = {
  transition: `transform ${duration}s ease-in-out`,
  transform: 'translateY(0px)',
};
export const transitionStyles: Partial<Record<TransitionStatus, Properties>> = {
  entering: { transform: 'translateY(30px)' },
  entered: { transform: 'translateY(30px)' },
  exiting: { transform: 'translateY(0px)' },
  exited: { transform: 'translateY(0px)' },
};
