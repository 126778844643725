import { FC } from 'react';

import styles from './MainLogoText.module.scss';

interface Props {
  isLogoMode?: boolean;
}

export const MainLogoText: FC<Props> = ({ isLogoMode }) => {
  if (!isLogoMode) return null;
  return (
    <p className={styles.MainLogoText}>
      <span>software</span>
    </p>
  );
};
