import { RouterProvider } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';

import { ErrorBoundary } from 'pages/ErrorPage/ErrorBoundary';
import { store } from './redux/store';
import { IS_PRODUCTION } from 'constants/constants';
import { router } from 'router/router';
import './routing.scss';
import './index.scss';

if (IS_PRODUCTION) {
  disableReactDevTools();
}
const app = (
  <ErrorBoundary>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </ErrorBoundary>
);
createRoot(document.getElementById('root')!).render(app);
