import { useAppDispatch } from 'redux/hooks';

import { useLanguage } from 'hooks/useLanguage/useLanguage';
import { UserSideMenuSectionItemType } from '../UserSideMenuSectionItem';
import { setModalWindowStatus } from 'redux/slices/main/exports';

export interface IUserSideMenuSectionItemData {
  title: string;
  onClick?: VoidFunction;
}

export const useUserSideMenuSectionItemData = (
  type: UserSideMenuSectionItemType,
): IUserSideMenuSectionItemData => {
  const dispatch = useAppDispatch();
  const language = useLanguage();
  const openModalWindowForLoggingOut = () => {
    dispatch(setModalWindowStatus('log out'));
  };
  switch (type) {
    case 'log out':
      return { title: language('logout'), onClick: openModalWindowForLoggingOut };
    default:
      return { title: '' };
  }
};
