import { FC, memo, useCallback, useRef } from 'react';
import { Transition } from 'react-transition-group';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import { getIsUserSideMenuOpened, setIsUserSideMenuOpened } from 'redux/slices/main/exports';
import { UserSideMenuContainer } from './components/UserSideMenuContainer';
import { UserSideMenuHead } from './components/components/UserSideMenuHead/UserSideMenuHead';
import { UserSideMenuSectionSeparator } from './components/components/UserSideMenuSectionSeparator/UserSideMenuSectionSeparator';
import { UserSideMenuSectionItem } from './components/components/UserSideMenuSectionItem/UserSideMenuSectionItem';
import { classNames } from 'helpers/classNames';
import {
  getBackgroundStyles,
  getSideMenuContainerStyles,
  getSideMenuStyles,
} from './transitionConstants';
import styles from './UserSideMenu.module.scss';

export const UserSideMenu: FC = memo(() => {
  const userSideMenuRef = useRef(null);
  const userSideMenuBackgroundRef = useRef(null);
  const userSideMenuContainerRef = useRef(null);
  const dispatch = useAppDispatch();
  const isUserSideMenuOpened = useAppSelector(getIsUserSideMenuOpened);

  const { defaultStyle, sideMenuTotalDuration, transitionStyles } =
    getSideMenuStyles(isUserSideMenuOpened);
  const { backgroundDefaultStyle, backgroundTransitionStyles, backgroundTransitionDuration } =
    getBackgroundStyles(isUserSideMenuOpened);
  const {
    sideMenuContainerDefaultStyle,
    sideMenuContainerTransitionStyles,
    sideMenuContainerTransitionDuration,
  } = getSideMenuContainerStyles(isUserSideMenuOpened);

  const closeUserSideMenu = useCallback(() => {
    dispatch(setIsUserSideMenuOpened(false));
  }, [dispatch]);
  return (
    <Transition
      in={isUserSideMenuOpened}
      timeout={sideMenuTotalDuration}
      nodeRef={userSideMenuRef}
      mountOnEnter
      unmountOnExit
    >
      {(state) => (
        <div
          className={classNames(styles.UserSideMenu, undefined, {
            [styles.opened]: isUserSideMenuOpened,
          })}
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
          ref={userSideMenuRef}
        >
          <Transition
            in={isUserSideMenuOpened}
            timeout={backgroundTransitionDuration}
            nodeRef={userSideMenuBackgroundRef}
          >
            <div
              className={styles.background}
              style={{
                ...backgroundDefaultStyle,
                ...backgroundTransitionStyles[state],
              }}
              ref={userSideMenuBackgroundRef}
              onClick={closeUserSideMenu}
            ></div>
          </Transition>
          <Transition
            in={isUserSideMenuOpened}
            timeout={sideMenuContainerTransitionDuration}
            nodeRef={userSideMenuContainerRef}
            mountOnEnter
            unmountOnExit
          >
            <UserSideMenuContainer
              style={{
                ...sideMenuContainerDefaultStyle,
                ...sideMenuContainerTransitionStyles[state],
              }}
              ref={userSideMenuContainerRef}
            >
              <UserSideMenuHead closeUserSideMenu={closeUserSideMenu} />
              <UserSideMenuSectionSeparator />
              <UserSideMenuSectionItem type="log out" />
            </UserSideMenuContainer>
          </Transition>
        </div>
      )}
    </Transition>
  );
});
