import { FC } from 'react';

import { useUserSideMenuSectionItemData } from './hooks/useUserSideMenuSectionItemData';
import styles from './UserSideMenuSectionItem.module.scss';

export type UserSideMenuSectionItemType = 'log out';

interface Props {
  type: UserSideMenuSectionItemType;
}

export const UserSideMenuSectionItem: FC<Props> = ({ type }) => {
  const { title, onClick } = useUserSideMenuSectionItemData(type);
  return (
    <div className={styles.UserSideMenuSectionItem}>
      <span onClick={onClick}>{title}</span>
    </div>
  );
};
