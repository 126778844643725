import { FC } from 'react';
import { Link } from 'react-router-dom';

import styles from './CustomLink.module.scss';

interface Props {
  linkText: string;
  linkUrl: string;
}

export const CustomLink: FC<Props> = ({ linkText, linkUrl }) => {
  return (
    <Link draggable={false} to={linkUrl} className={styles.CustomLink}>
      {linkText}
    </Link>
  );
};
