import { useAppDispatch, useAppSelector } from 'redux/hooks';

import { getCanLinkBeDispatched } from 'components/TextStyled/components/CustomLink/utils';
import { CurrentlyViewedPageType } from 'redux/types';
import { getCurrentlyViewedPage, setCurrentlyViewedPage } from 'redux/slices/main/exports';

export const useCurrentlyViewedPage = () => {
  const dispatch = useAppDispatch();
  const currentlyViewedPage = useAppSelector(getCurrentlyViewedPage);
  return () => {
    const linkUrl = (location.pathname.slice(1) as CurrentlyViewedPageType) || 'home';
    const canLinkBeDispatched = getCanLinkBeDispatched(linkUrl);
    const isPageCurrent = currentlyViewedPage === linkUrl;
    canLinkBeDispatched && !isPageCurrent && dispatch(setCurrentlyViewedPage(linkUrl));
  };
};
