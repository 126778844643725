import { FC, memo, useCallback, useEffect } from 'react';
import { useLocation, useOutlet } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { useAppSelector, useAppDispatch } from '../redux/hooks';
import { useNavigate } from 'react-router-dom';

import { useLocalStorageSave } from '../hooks/useLocalStorageSave';
import { useLocalStorageLoad } from '../hooks/useLocalStorageLoad';
import { WebSocketType } from 'types/types';
import {
  getCurrentLanguage,
  getIsAuthModalActive,
  setOnlineUsersNicknames,
  setIsAuthModalActive,
  getCurrentlyViewedPage,
  setCurrentlyViewedPage,
} from 'redux/slices/main/exports';
import { getIsAuth } from 'redux/slices/user/exports';
import { SystemButtons } from './SystemButtons/SystemButtons';
import { LanguageSwitcher } from './SystemButtons/components/LanguageSwitcher/LanguageSwitcher';
import { NavLinksGroup } from './SystemButtons/components/NavLinksGroup/NavLinksGroup';
import { NavLink } from './SystemButtons/components/NavLinksGroup/components/NavLink/NavLink';
import { Notificator } from './Notificator/Notificator';
import { HEART_ICON_SRC } from 'constants/assets-src';
import { UserSideMenuOpener } from './SystemButtons/components/NavLinksGroup/components/UserSideMenuOpener/UserSideMenuOpener';
import { Copyright } from 'components/Copyright/Copyright';
import { ModalWindow } from './ModalWindow/ModalWindow';
import { routes } from 'router/routes/main';
import { PAGE_CHANGE_DURATION } from 'constants/gsapConstants';
import { UserSideMenu } from './UserSideMenu/UserSideMenu';
import { RoutesEnum } from 'router/routes';
import { useCurrentlyViewedPage } from 'hooks/useCurrentlyViewedPage';
import { usePasswordRecovery } from 'hooks/usePasswordRecovery';

interface Props {
  socket: WebSocketType;
}

export const App: FC<Props> = memo(({ socket }) => {
  const location = useLocation();
  const currentOutlet = useOutlet();
  const updateCurrentlyViewedPage = useCurrentlyViewedPage();
  const pageSwitchingDuration = PAGE_CHANGE_DURATION * 1000;
  const { nodeRef } = routes.find((route) => route.path === location.pathname) ?? {};
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const localStorageSave = useLocalStorageSave();
  const localStorageLoad = useLocalStorageLoad();
  const { clearPasswordRecoveringData, disablePasswordRecoveryMode } = usePasswordRecovery();
  const currentLanguage = useAppSelector(getCurrentLanguage);
  const isAuth = useAppSelector(getIsAuth);
  const isAuthModalActive = useAppSelector(getIsAuthModalActive);
  const currentlyViewedPage = useAppSelector(getCurrentlyViewedPage);
  const isAuthRoute = currentlyViewedPage === 'signin' || currentlyViewedPage === 'signup';
  const shouldAuthRouteBeSwitched = isAuthRoute && isAuth;

  useEffect(() => {
    if (shouldAuthRouteBeSwitched) {
      navigate(RoutesEnum.SPORT_ASSISTANT_URL, { replace: true });
      dispatch(setCurrentlyViewedPage('assistant'));
    }
  }, [dispatch, navigate, shouldAuthRouteBeSwitched]);

  const websocketsConnectionHandler = () => {
    console.log('WebSocket connection has been established...');
  };

  const updateOnlineUsersNicknames = useCallback(
    (onlineUsersNicknames: Array<string>) => {
      dispatch(setOnlineUsersNicknames(onlineUsersNicknames));
    },
    [dispatch],
  );

  useEffect(() => {
    socket.on('connect', websocketsConnectionHandler);
    socket.on('onlineUsersUpdate', updateOnlineUsersNicknames);
    return () => {
      socket.off('connect', websocketsConnectionHandler);
      socket.off('onlineUsersUpdate', updateOnlineUsersNicknames);
    };
  }, [socket, updateOnlineUsersNicknames]);

  useEffect(() => {
    document.documentElement.lang = currentLanguage === 'ru' ? 'ru' : 'en';
  }, [currentLanguage]);

  useEffect((): void => {
    if (isAuth) {
      isAuthModalActive && dispatch(setIsAuthModalActive(false));
      clearPasswordRecoveringData();
    }
    localStorageSave();
  }, [clearPasswordRecoveringData, dispatch, isAuth, isAuthModalActive, localStorageSave]);

  useEffect(() => {
    updateCurrentlyViewedPage();
    disablePasswordRecoveryMode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, dispatch]);

  useEffect(() => {
    localStorageLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <SystemButtons>
        <NavLinksGroup
          type="main-application"
          mainChild={
            <NavLink
              origin="main-application"
              isMainLinkInGroup
              isAppLink
              linkName="mySportCap"
              linkSrc={'assistant'}
            />
          }
        />
        <NavLinksGroup
          type="information"
          mainChild={
            <NavLink origin="information" isMainLinkInGroup linkName="infoCap" linkSrc={'info'} />
          }
        >
          <NavLink
            origin="information"
            linkName="supportCap"
            linkSrc={'support'}
            iconSrc={HEART_ICON_SRC}
            iconAltText="heart"
          />
          <NavLink origin="information" linkName="projectsCap" linkSrc={'projects'} />
          <NavLink origin="information" linkName="feedbackCap" linkSrc={'feedback'} />
        </NavLinksGroup>
        <NavLinksGroup
          type="authorization"
          mainChild={
            <NavLink
              origin="authorization"
              isMainLinkInGroup
              linkName="signInCap"
              linkSrc={'signin'}
            />
          }
          userSideMenuToggler={<UserSideMenuOpener />}
          isAuthorizationLinksGroup
        >
          <NavLink origin="authorization" linkName="signUpCap" linkSrc={'signup'} />
        </NavLinksGroup>
        <LanguageSwitcher />
      </SystemButtons>
      <SwitchTransition>
        <CSSTransition
          unmountOnExit
          key={location.pathname}
          nodeRef={nodeRef}
          timeout={pageSwitchingDuration}
          classNames="page"
        >
          {() => (
            <div ref={nodeRef} className="page">
              {currentOutlet}
            </div>
          )}
        </CSSTransition>
      </SwitchTransition>
      <Copyright />
      <Notificator />
      <ModalWindow />
      <UserSideMenu />
    </>
  );
});
