import { FC, memo, useRef } from 'react';
import { useAppSelector } from 'redux/hooks';

import { TextStyled } from 'components/TextStyled/TextStyled';
import { getCurrentLanguage } from 'redux/slices/main/exports';
import { language } from 'utils/language';
import { RegistrationQuestionType } from 'types/types';
import { RegistrationApplyWrapper } from './components/RegistrationApplyWrapper/RegistrationApplyWrapper';
import { getRegistrationQuestionText } from './utils';
import { getCurrentRegistrationQuestionNumber } from 'redux/slices/user/exports';
import { getRegistrationQuestionNumberFromType } from '../../utils';
import { RegistrationQuestionAnswer } from './components/RegistrationQuestionAnswer/RegistrationQuestionAnswer';
import { ButtonRemastered } from 'components/ButtonRemastered/ButtonRemastered';
import { useAuthorizationDataError } from 'pages/hooks/useAuthorizationDataError';
import { AuthorizationDataValidationInfo } from 'components/AuthorizationDataValidationInfo/AuthorizationDataValidationInfo';
import { useAnimateAppearing } from 'hooks/GSAP/useAnimateAppearing';
import styles from './RegistrationQuestion.module.scss';

interface Props {
  type: RegistrationQuestionType;
}

export const RegistrationQuestion: FC<Props> = memo(({ type }) => {
  const registrationQuestionRef = useRef<HTMLDivElement>(null);
  const currentLanguage = useAppSelector(getCurrentLanguage);
  const currentRegistrationQuestionNumber = useAppSelector(getCurrentRegistrationQuestionNumber);
  const { isValidatedFieldEmpty, validationError } = useAuthorizationDataError('registration');
  const registrationQuestionText = getRegistrationQuestionText(type);
  const registrationQuestionNumber = getRegistrationQuestionNumberFromType(type);
  const isRegistrationQuestionActive =
    registrationQuestionNumber === currentRegistrationQuestionNumber;
  const isQuestionLast = type === 'check';
  useAnimateAppearing(isRegistrationQuestionActive, registrationQuestionRef);
  return (
    <div className={styles.RegistrationQuestion} ref={registrationQuestionRef}>
      <TextStyled text={language(currentLanguage, registrationQuestionText)} />
      <RegistrationApplyWrapper
        type={type}
        validationError={validationError}
        isValidatedFieldEmpty={isValidatedFieldEmpty}
      >
        {!isQuestionLast && <RegistrationQuestionAnswer />}
        {type === 'password' ? <RegistrationQuestionAnswer isRepeatedPassword /> : null}
        <ButtonRemastered isWideButton={isQuestionLast} />
      </RegistrationApplyWrapper>
      <AuthorizationDataValidationInfo validationError={validationError} />
    </div>
  );
});
