import { FC, memo, useCallback, useRef } from 'react';
import { useAppSelector } from 'redux/hooks';

import { LoginLogo } from './components/LoginLogo/LoginLogo';
import { LoginForm } from './components/LoginForm/LoginForm';
import { LoginInput } from './components/LoginForm/components/LoginInput/LoginInput';
import { NoAccountTextWithLink } from './components/NoAccountTextWithLink/NoAccountTextWithLink';
import { ButtonRemastered } from 'components/ButtonRemastered/ButtonRemastered';
import { language } from 'utils/language';
import { getCurrentLanguage, getIsPasswordRecoveryMode } from 'redux/slices/main/exports';
import { useAuthorizationDataError } from 'pages/hooks/useAuthorizationDataError';
import { getAuthStatus } from 'redux/slices/user/exports';
import { useThunks } from 'redux/thunks/hooks/useThunks';
import { AuthorizationDataValidationInfo } from 'components/AuthorizationDataValidationInfo/AuthorizationDataValidationInfo';
import { PasswordRecoveryModeSwitcher } from './components/PasswordRecoveryModeSwitcher/PasswordRecoveryModeSwitcher';
import { LoginSemanticBlock } from './components/LoginSemanticBlock/LoginSemanticBlock';
import { PasswordRecoveryBlock } from './components/PasswordRecoveryBlock/PasswordRecoveryBlock';
import { useAnimateAppearing } from 'hooks/GSAP/useAnimateAppearing';
import { usePasswordRecovery } from 'hooks/usePasswordRecovery';
import { CodeConfirmationBlock } from './components/CodeConfirmationBlock/CodeConfirmationBlock';
import styles from './LoginSection.module.scss';

export const LoginSection: FC = memo(() => {
  const loginSemanticBlockRef = useRef<HTMLDivElement>(null);
  const codeConfirmationBlockRef = useRef<HTMLDivElement>(null);
  const passwordEnteringSemanticBlockRef = useRef<HTMLDivElement>(null);
  const passwordRecoverySemanticBlockRef = useRef<HTMLDivElement>(null);
  const {
    isChangingPasswordBlockShown,
    isConfirmationRecoveryCodeBlockShown,
    isGettingRecoveryCodeBlockShown,
    isLoginBlockShown,
  } = usePasswordRecovery();
  const isPasswordRecoveryMode = useAppSelector(getIsPasswordRecoveryMode);
  const currentLanguage = useAppSelector(getCurrentLanguage);
  const { isValidatedFieldEmpty, validationError } = useAuthorizationDataError('login');
  const {
    isValidatedFieldEmpty: isValidatedFieldEmptyRecoveryMode,
    validationError: validationErrorRecoveryMode,
  } = useAuthorizationDataError('password-recovering');
  const isLoading = useAppSelector(getAuthStatus) === 'loading';
  const { login } = useThunks();
  const isLoginButtonDisabled = isLoading
    ? true
    : !isPasswordRecoveryMode
    ? Boolean(validationError || isValidatedFieldEmpty)
    : Boolean(isValidatedFieldEmptyRecoveryMode || validationErrorRecoveryMode);
  useAnimateAppearing(isGettingRecoveryCodeBlockShown, passwordRecoverySemanticBlockRef);
  useAnimateAppearing(!isGettingRecoveryCodeBlockShown, passwordEnteringSemanticBlockRef);
  useAnimateAppearing(isLoginBlockShown, loginSemanticBlockRef);
  useAnimateAppearing(isConfirmationRecoveryCodeBlockShown, codeConfirmationBlockRef);

  const loginHandler = useCallback(() => {
    if (isLoginButtonDisabled) return;
    login();
  }, [isLoginButtonDisabled, login]);
  return (
    <div className={styles.LoginSection}>
      <LoginLogo />
      <LoginForm>
        <LoginSemanticBlock ref={codeConfirmationBlockRef}>
          <CodeConfirmationBlock
            isConfirmationRecoveryCodeBlockShown={isConfirmationRecoveryCodeBlockShown}
          />
        </LoginSemanticBlock>
        <LoginSemanticBlock ref={loginSemanticBlockRef}>
          <LoginInput type="email" isLoginButtonDisabled={isLoginButtonDisabled} />
          <LoginSemanticBlock ref={passwordRecoverySemanticBlockRef}>
            <PasswordRecoveryBlock />
          </LoginSemanticBlock>
        </LoginSemanticBlock>
        <LoginSemanticBlock ref={passwordEnteringSemanticBlockRef}>
          <LoginInput type="password" isLoginButtonDisabled={isLoginButtonDisabled} />
          <PasswordRecoveryModeSwitcher />
          <ButtonRemastered
            isDisabled={isLoginButtonDisabled}
            isWideButton={true}
            isFullWidth={true}
            buttonText={language(currentLanguage, 'signInCap')}
            onClick={loginHandler}
          />
          <AuthorizationDataValidationInfo validationError={validationError} />
        </LoginSemanticBlock>
      </LoginForm>
      <NoAccountTextWithLink />
    </div>
  );
});
