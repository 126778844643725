import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getLoginData } from 'redux/slices/user/exports';
import {
  checkPasswordRecoveringCodeAsync,
  recoverUserPasswordAsync,
  userLoginAsync,
} from '../userThunks';
import { getCurrentLanguage } from 'redux/slices/main/exports';
import { useCallback } from 'react';

export const useThunks = () => {
  const dispatch = useAppDispatch();
  const currentLanguage = useAppSelector(getCurrentLanguage);
  const loginData = useAppSelector(getLoginData);
  const { email: loginEmail } = loginData;

  const login = useCallback(
    () => dispatch(userLoginAsync({ ...loginData, lang: currentLanguage })),
    [currentLanguage, dispatch, loginData],
  );
  const getPasswordRecoveryCode = useCallback(
    () => dispatch(recoverUserPasswordAsync({ email: loginEmail, lang: currentLanguage })),
    [currentLanguage, dispatch, loginEmail],
  );
  const confirmRecoveryCode = useCallback(
    (recoveryCode: string) => {
      dispatch(
        checkPasswordRecoveringCodeAsync({
          email: loginEmail,
          lang: currentLanguage,
          recoveryCode,
        }),
      );
    },
    [currentLanguage, dispatch, loginEmail],
  );
  return { login, getPasswordRecoveryCode, confirmRecoveryCode };
};
