import { memo, forwardRef } from 'react';

import styles from './LoginSemanticBlock.module.scss';
import { ReactChildrenType } from 'types/types';

interface Props {
  children?: ReactChildrenType;
}

export const LoginSemanticBlock = memo(
  forwardRef<HTMLDivElement, Props>(({ children }, ref) => {
    return (
      <div className={styles.LoginSemanticBlock} ref={ref}>
        {children}
      </div>
    );
  }),
);
